<template>
  <div>
    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent && bind.boolCarregado"
    >
      <v-tabs v-model="strTab" centered icons-and-text>
        <v-tab
          :href="'#tab-' + integracao.strIdentificador"
          v-for="(integracao, index) in bind.arrayIntegracao"
          :key="index"
        >
          {{ integracao.strNome }}
          <a>
            <img  width="40"
              :src="'/img/integracao/' + integracao.strIdentificador + '.png'"
          /></a>
        </v-tab>
      </v-tabs>
      <integracao-bradesco :formData="formData" v-if="formData && strTab == 'tab-bradesco'" :key="strTab + $root.$session.versao"  />
      <integracao-inter :formData="formData" v-if="formData && strTab == 'tab-inter'" :key="strTab + $root.$session.versao"  />
      <integracao-sicoob :formData="formData" v-if="formData && strTab == 'tab-sicoob'" :key="strTab + $root.$session.versao"  />
    </ed-form>
  </div>
</template>

<script>
import { EdForm, EdButton } from "@/components/common/form";

import IntegracaoInter from "./partials/inter.vue";
import IntegracaoBradesco from "./partials/bradesco.vue";
import IntegracaoSicoob from "./partials/sicoob.vue";

export default {
  name: "pageCadastroFuncao",
  props: {},
  components: {
    EdForm,
    IntegracaoBradesco,
    IntegracaoInter,
    IntegracaoSicoob
  },
  mounted() {
    this.initialize();
  },
  created() {},
  provide() {
    return {
      bind: this.bind,
    };
  },
  data() {
    return {
      strTab: null,
      bind: {
        boolCarregado:false,
        arrayIntegracao: [],
        arrayContaBancaria: [],
        arrayFormaPagamento:[]
      },
      formData: null,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
    strTab(){
      this.changeIntegracao()
    }
  },
  computed: {},
  methods: {

    changeIntegracao(){

      let formData = null 
      let self = this

      this.bind.arrayIntegracao.forEach(element => {
        if(self.strTab == 'tab-'+element.strIdentificador){
          formData = element

          if(element.strValor && element.strValor.length){
            formData.intConfiguracaoId = element.strValor[0].intId 
          }
        }
      });

      this.formData = Object.assign(formData,{})
    },

    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Configuracoes/CanalIntegracao", {
          boolInicializar: true,
        }),
      ]).then(([objDados]) => {

        if (objDados.status == 200 && objDados.result) {
          this.bind.arrayIntegracao =objDados.result.arrayIntegracao.data;
          this.bind.arrayContaBancaria = objDados.result.arrayContaBancaria.data;
          this.bind.arrayFormaPagamento = objDados.result.arrayParametros['financeiroFormaPagamento'].parametros;
        }

        if(this.bind.arrayIntegracao.length && !this.formData){
          this.formData = this.bind.arrayIntegracao[0]

          if(this.formData.strValor.length){
            this.formData.intConfiguracaoId = 1;
          }
    
          this.strTab = 'tab-'+this.bind.arrayIntegracao.strIdentificador
        }

        this.$root.$dialog.loading(false);
        this.bind.boolCarregado = true
      });
    },

    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Configuracoes/CanalIntegracao", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.initialize();
          }
        });
    },
  },
};
</script>
