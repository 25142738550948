<template>
  <div>
    <ed-canal-integracao :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdCanalIntegracao from "@/components/configuracoes/canalIntegracao/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdCanalIntegracao },
  mounted() {

  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>