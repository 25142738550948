<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
        <span class="form-title">Informações Gerais</span>

        <div class="row">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.intConfiguracaoId"
            label="Configuração"
            name="intConfiguracaoId"
            :items="formData.strValor"
            rules="required"
          />
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-3 col-xl-3 mt-7">
            <ed-button
              label="Adicionar"
              color="success"
              :iconLeft="$utilidade.getIcone('cadastrar')"
              @click="adicionarConfiguracao"
            />
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-2 col-lg-3 col-xl-3 mt-7"
            v-if="formData.intConfiguracaoId"
          >
            <ed-button
              label="Remover"
              color="error"
              :iconLeft="$utilidade.getIcone('deletar')"
              @click="removerConfiguracao"
            />
          </div>
        </div>

        <div class="row" v-if="formData.intConfiguracaoId">
          <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intContaBancariaId
            "
            label="Conta Bancária"
            name="intContaBancariaId"
            item-text="strNome"
            route="Configuracoes/ContaBancaria"
            :filters="{
              strNumeroBanco: formData.strCodigoIntegracao,
            }"
            :initializeItems="true"
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].arrayFormaPagamento
            "
            label="Formas de Pagamento"
            name="arrayFormaPagamento"
            item-text="strNome"
            item-value="strChave"
            :items="bind.arrayFormaPagamento"
            multiple
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strCarteira
            "
            name="strCarteira"
            label="Carteira"
            rules="required"
            :items="[{intId:'112',strNome:'112'}]"
            :disabled="formData.boolAtivo ? false : true"
          />
          <!-- <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strNumeroAplicacao
            "
            name="strNumeroAplicacao"
            label="Número da Aplicação"
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          /> -->

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strValor[getIndexFormularioAtivo()].strClientId"
            name="strClienteId"
            label="Client ID"
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strCooperativa
            "
            name="strCooperativa"
            label="Cooperativa"
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strNumeroContrato
            "
            name="strNumeroContrato"
            label="Número do Contrato"
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-money
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strValor[getIndexFormularioAtivo()].floatJuros"
            name="floatJuros"
            label="Taxa Juros Mensal"
            rules=""
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intCobrarJurosApos
            "
            name="intCobrarJurosApos"
            label="Cobrar Juros Após (dias)"
            rules=""
            type="number"
          />

          <ed-input-money
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strValor[getIndexFormularioAtivo()].floatMulta"
            name="floatMulta"
            label="Taxa Multa"
            rules=""
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intCobrarMultaApos
            "
            name="intCobrarMultaApos"
            label="Cobrar Multa Após (dias)"
            rules=""
            type="number"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="
              formData.strValor[getIndexFormularioAtivo()]
                .intDataLimitePagamento
            "
            name="intDataLimitePagamento"
            label="Data Limite para Pagamento (dias)"
            rules=""
            type="number"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-file
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strCertificado
            "
            name="strCertificado"
            label="Certificado"
            rules="required"
            info="Esse é o certificado A1"
            accept="*.crt"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strSenhaCertificado
            "
            name="strSenhaCertificado"
            label="Senha do Certificado"
            rules="required"
            type="password"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text-area
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intCobrarMultaApos
            "
            name="strInstrucoes"
            label="Instruções"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Habilitar Integração?"
          />
          <div class="col-12 mt-5 mb-5 text-center">
            <ed-button
              label="Testar Integração"
              color="warning"
              @click="testar"
              :disabled="formData.boolAtivo ? false : true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputMoney,
  EdInputFile,
  EdButton,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputMoney,
    EdInputFile,
    EdButton,
    EdInputAutoComplete,
  },
  inject: ["bind"],
  mounted() {},
  created() {},
  data() {
    return {
      arrayTipoAutenticacao: [
        { intId: "tls", strNome: "TLS" },
        //{ intId: "STARTTLS", strNome: "STARTTLS" },
        { intId: "ssl", strNome: "SSL" },
      ],
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
      },
      deep: true,
    },
  },
  methods: {
    adicionarConfiguracao() {
      let intConfiguracaoId = this.formData.strValor.length + 1;

      this.formData.strValor.push({
        intId: intConfiguracaoId,
        strNome: "Configuração " + intConfiguracaoId,
        arrayFormaPagamento: ["financeiroFormaPagamentoBoleto"],
        intContaBancariaId: null,
        boolAtivo: 1,
        intDataLimitePagamento: 30,
        intBaixaAutomatica:0,
        strClientId: null,
        strSenhaCertificado: null,
        strNumeroContrato: null,
        strCooperativa:null,
        floatJuros: null,
        intCobrarJurosApos: null,
        floatMulta: null,
        intCobrarMultaApos: null,
        strInstrucoes: null,
        strCertificado: null,
        strChave: null,
      });

      this.formData.intConfiguracaoId = intConfiguracaoId;
    },

    removerConfiguracao() {
      this.formData.strValor.splice(this.getIndexFormularioAtivo(), 1);
      if (this.formData.strValor.length) {
        let formAtivo =
          this.formData.strValor[this.formData.strValor.length - 1];
        this.formData.intConfiguracaoId = formAtivo.intId;
      } else {
        this.formData.intConfiguracaoId = null;
      }
    },

    testar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Configuracoes/CanalIntegracao", {
          boolTestarIntegracao: true,
          intCanalVendaId: this.formData.intId,
          objConfiguracao:
            this.formData.strValor[this.getIndexFormularioAtivo()],
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.$root.$message.success(
              "Integração está funcionando corretamente"
            );
          }
        });
    },

    getIndexFormularioAtivo() {
      let self = this;

      return this.formData.strValor.findIndex(function (obj) {
        return obj.intId == self.formData.intConfiguracaoId;
      });
    },
  },
};
</script>
