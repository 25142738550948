<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
        <span class="form-title">Informações Gerais</span>

        <div class="row">
          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.intConfiguracaoId"
            label="Configuração"
            name="intConfiguracaoId"
            :items="formData.strValor"
            rules="required"
          />
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-3 col-xl-3 mt-7">
            <ed-button
              label="Adicionar"
              color="success"
              :iconLeft="$utilidade.getIcone('cadastrar')"
              @click="adicionarConfiguracao"
            />
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-2 col-lg-3 col-xl-3 mt-7"
            v-if="formData.intConfiguracaoId"
          >
            <ed-button
              label="Remover"
              color="error"
              :iconLeft="$utilidade.getIcone('deletar')"
              @click="removerConfiguracao"
            />
          </div>
        </div>

        <div class="row" v-if="formData.intConfiguracaoId">
          <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intContaBancariaId
            "
            label="Conta Bancária"
            name="intContaBancariaId"
            item-text="strNome"
            route="Configuracoes/ContaBancaria"
            :filters="{
              strNumeroBanco: formData.strCodigoIntegracao,
            }"
            :initializeItems="true"
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].arrayFormaPagamento
            "
            label="Formas de Pagamento"
            name="arrayFormaPagamento"
            item-text="strNome"
            item-value="strChave"
            :items="bind.arrayFormaPagamento"
            multiple
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-select
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strCarteira
            "
            name="strCarteira"
            label="Carteira"
            rules="required"
            :items="[{intId:'09',strNome:'09'}]"
            :disabled="formData.boolAtivo ? false : true"
          />

          <!-- <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strNumeroAplicacao
            "
            name="strNumeroAplicacao"
            label="Número da Aplicação"
            rules="required"
            :disabled="formData.boolAtivo ? false : true"
          /> -->

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intSequencialRemessa
            "
            name="intSequencialRemessa"
            label="Sequencial da Remessa"
            rules="required"
            type="number"
            info="Esse parâmetro é fornecido pelo seu banco. Caso esse seja seu primeiro acesso deixe esse parâmetro preenchido com 1 (um)"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strAcessorioEstritural
            "
            name="strAcessorioEstritural"
            label="Acessório Escritural"
            rules="required"
            type="number"
            info="Esse parâmetro é fornecido pelo seu banco. É seu código dentro do banco. Entre em contato com eles caso não souber qual é esse código."
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-money
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strValor[getIndexFormularioAtivo()].floatJuros"
            name="floatJuros"
            label="Taxa Juros Mensal"
            rules=""
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intCobrarJurosApos
            "
            name="intCobrarJurosApos"
            label="Cobrar Juros Após (dias)"
            rules=""
            type="number"
          />

          <ed-input-money
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strValor[getIndexFormularioAtivo()].floatMulta"
            name="floatMulta"
            label="Taxa Multa"
            rules=""
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intCobrarMultaApos
            "
            name="intCobrarMultaApos"
            label="Cobrar Multa Após (dias)"
            rules=""
            type="number"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intDataLimitePagamento
            "
            name="intDataLimitePagamento"
            label="Data Limite para Pagamento (dias)"
            rules=""
            type="number"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intBaixaAutomatica
            "
            name="intBaixaAutomatica"
            label="Cancelar Boleto Automaticamente em (dias)"
            rules=""
            type="number"
            info="Número de dias corridos após o vencimento para o cancelamento efetivo automático do boleto. (de 1 até 60)"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strSenhaArquivo
            "
            name="strSenhaArquivo"
            label="Senha do Arquivo"
            rules=""
            type="password"
            :disabled="formData.boolAtivo ? false : true"
          />
          

          <ed-input-file
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strArquivoTransferencia
            "
            name="strArquivoTransferencia"
            label="Arquivo Transferência"
            rules="required"
            info="Esse arquivo você deve gerar no Net Empresa do Bradesco. Entre em contato com eles para saber mais a respeito."
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-file
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].strArquivoCriptografia
            "
            name="strArquivoCriptografia"
            label="Arquivo Criptografia"
            rules="required"
            info="Esse arquivo você deve gerar no Net Empresa do Bradesco. Entre em contato com eles para saber mais a respeito."
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-text-area
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="
              formData.strValor[getIndexFormularioAtivo()].intCobrarMultaApos
            "
            name="strInstrucoes"
            label="Instruções"
            :disabled="formData.boolAtivo ? false : true"
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Habilitar Integração?"
          />
          <div class="col-12 mt-5 mb-5 text-center">
            <ed-button
              label="Testar Integração"
              color="warning"
              @click="testar"
              :disabled="formData.boolAtivo ? false : true"
            />

            <ed-button
              class="ml-2"
              label="Processar Boletos"
              color="success"
              @click="baixar"
              :disabled="formData.boolAtivo ? false : true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputMoney,
  EdInputFile,
  EdButton,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputMoney,
    EdInputFile,
    EdButton,
    EdInputAutoComplete,
  },
  inject: ["bind"],
  mounted() {},
  created() {},
  data() {
    return {
      arrayTipoAutenticacao: [
        { intId: "tls", strNome: "TLS" },
        //{ intId: "STARTTLS", strNome: "STARTTLS" },
        { intId: "ssl", strNome: "SSL" },
      ],
    };
  },
  watch: {},
  methods: {
    adicionarConfiguracao() {
      let intConfiguracaoId = this.formData.strValor.length + 1;

      this.formData.strValor.push({
        intId: intConfiguracaoId,
        strNome: "Configuração " + intConfiguracaoId,
        arrayFormaPagamento:['financeiroFormaPagamentoBoleto'],
        intContaBancariaId: null,
        intSequencialRemessa:null,
        strAcessorioEstritural:null,
        strArquivoCriptografia:null,
        strArquivoTransferencia:null,
        boolAtivo: 1,
        intDataLimitePagamento:30,
        intBaixaAutomatica:0,
        strCarteira: '09',
        floatJuros: null,
        intCobrarJurosApos: null,
        floatMulta: null,
        intCobrarMultaApos: null,
        strInstrucoes: null,
      });

      this.formData.intConfiguracaoId = intConfiguracaoId;
    },

    removerConfiguracao() {
      this.formData.strValor.splice(this.getIndexFormularioAtivo(), 1);
      if (this.formData.strValor.length) {
        let formAtivo =
          this.formData.strValor[this.formData.strValor.length - 1];
        this.formData.intConfiguracaoId = formAtivo.intId;
      } else {
        this.formData.intConfiguracaoId = null;
      }
    },

    testar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Configuracoes/CanalIntegracao", {
          boolTestarIntegracao:true,
          intCanalVendaId:this.formData.intId,
          objConfiguracao:this.formData.strValor[this.getIndexFormularioAtivo()]

        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.$root.$message.success("Integração está funcionando corretamente");
          }
        });
    },

    baixar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Configuracoes/CanalIntegracao", {
          boolBaixarBoletos:true,
          boolProcessarPagamentos:true,
          intCanalVendaId:this.formData.intId,
          objConfiguracao:this.formData.strValor[this.getIndexFormularioAtivo()]

        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.$root.$message.success("Processo executado com sucesso");
          }
        });
    },

    getIndexFormularioAtivo() {
      let self = this;

      return this.formData.strValor.findIndex(function (obj) {
        return obj.intId == self.formData.intConfiguracaoId;
      });
    },
  },
};
</script>
